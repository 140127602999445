import { HeadeSearch } from "@components/head-search";
import { PageFooter } from "@components/page-footer";
import { PageTop } from "@components/page-top";
import { Cascader } from "@components/cascader";
import dataList from "@components/cascader/data.json";
export default {
  data: function data() {
    return {
      showRightList: true,
      columnType: "imported",
      options: dataList
    };
  },
  methods: {
    onSearch: function onSearch() {}
  },
  components: {
    HeadeSearch: HeadeSearch,
    PageFooter: PageFooter,
    PageTop: PageTop,
    Cascader: Cascader
  }
};