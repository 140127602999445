var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop'), _c('HeadeSearch', {
    attrs: {
      "headerTit": "测试标题",
      "inputWidth": "370"
    },
    on: {
      "onSearch": _vm.onSearch
    }
  }), _c('Cascader', {
    attrs: {
      "options": _vm.options
    }
  }), _c('PageFooter')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };